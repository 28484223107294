<template>
  <!-- 信链申请记录 -->
  <div class="page-card-demo  page-info-content">
    <!-- 搜索筛选 -->
    <FormSearch
      :getdata="getDate"
      :form-inline="formInline"
      :form-item-arr="formItemArr"
    >
      <el-form-item label="应收账款到期日" label-width="200px">
        <el-date-picker
          v-model="formInline.acctDate"
          type="daterange"
          format="yyyy-MM-dd"
          value-format="yyyy-MM-dd HH:mm:ss"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        />
      </el-form-item>
      <el-form-item label="信链申请金额" prop="" class="inline-form">
        <el-input
          v-model.trim="formInline.applyAmtBegin"
          class="br-input"
          placeholder=""
          clearable
          oninput="!/^(\d+\.?)?\d{0,2}$/.test(this.value)?(this.value=this.value.substring(0, this.value.length-1)): ''"
          @keyup.enter.native="search"
        /> -
        <el-input
          v-model.trim="formInline.applyAmtEnd"
          class="br-input"
          placeholder=""
          clearable
          oninput="!/^(\d+\.?)?\d{0,2}$/.test(this.value)?(this.value=this.value.substring(0, this.value.length-1)): ''"
          @keyup.enter.native="search"
        />
      </el-form-item>
    </FormSearch>
    <div>
      <Table
        :item-data="itemData"
        :list-data="listData"
        :operation-button="operationButton"
        :loading="loading"
      >
        <template #billNo="{row}">
          <el-button type="text" style="white-space: normal" @click="goPage(row,1)">
            {{ row.billNo }}
          </el-button>
        </template>
        <template #echainState="{row}">
          {{ $codeNew(echainState,row.echainState) }}
        </template>
      </Table>
      <!-- 分页 -->
      <Pagination :params="formInline" :total="total" :in-article="listData.length" :get-data-list="getDate" />
    </div>
  </div>
</template>

<script>
import { selectCmpApplaylist } from '@/api/tradeFinancing.js'
import FormSearch from '@/components/FormSearch'
import Pagination from '@/components/Pagination2'
import Table from '@/components/Table.vue'
export default {
  components: { FormSearch, Pagination, Table },
  data() {
    return {
      formInline: {
      },
      formItemArr: [
        { type: 'input', label: '开立方名称', value: 'openCmpName', width: '120px' },
        { type: 'input', label: '申请方', value: 'recvCmpName', width: '80px' },
        { type: 'input', label: '信链编号', value: 'echainNo', width: '80px' }
      ],
      itemData: [
        { label: '信链编号', prop: 'echainNo', width: 120 },
        { label: '开立方名称', prop: 'openCmpName', width: 120 },
        { label: '申请方名称', prop: 'recvCmpName', width: 280 },
        { label: '信链申请金额', prop: 'echainApplyAmt', width: 180 },
        { label: '应收账款编号', prop: 'billNo', width: 180 },
        { label: '应收账款到期日', prop: 'acctDate', width: 180 },
        { label: '信链状态', prop: 'echainState', width: 180 }
      ],
      operationButton: [
        { bType: 'primary', label: '查看详情', handleEvent: this.viewDetails },
        { bType: 'primary', label: '最终付款明细表', handleEvent: this.paymentDetails, child: [{ val: 'echainStateBtn' }] }

      ],
      listData: [],
      total: 0,
      loading: false,
      dateRange: []
    }
  },
  computed: {
    echainState() {
      return this.$store.getters.getDictionaryItem('ECHAIN_STATE')
    }
  },
  methods: {
    getDate(type) {
      if (type === 'reset') {
        this.formInline = {
          pageSize: 10,
          pageNum: 1,
          state: '',
          goodsCategoryArr: []
        }
        this.dateRange = []
      } else if (!type) {
        this.formInline.pageNum = 1
      }
      selectCmpApplaylist(this.formInline, res => {
        this.listData = [...res.data.list].map(item => {
          if (item.echainState === '5' || item.echainState === '6') {
            item.echainStateBtn = true
          }
          return item
        })
        this.total = res.data.total
      })
    },
    goPage(row, type) { // type==1 打开应收账款详情页  2 查看详情  3最终付款明细表
      this.$router.push({
        path: '/accountsDetails',
        query: {
          id: row.billId
        }
      })
    },
    viewDetails(row) {
      this.$router.push({
        name: 'applyRecordDetails',
        query: {
          id: row.id
        }
      })
    },
    paymentDetails(row) {
      this.$router.push({
        name: 'paymentDetails',
        query: {
          id: row.id
        }
      })
    }
  }
}
</script>

<style lang="scss">
.inline-form .el-form-item__content{
  display: flex !important;
  .el-input {
    min-width: 85px !important;
  }
}

</style>
